<template>
  <div class="deposit_container">
    <div class="header">
      <van-field readonly clickable label="选择住院人" :value="patName" @click="patNamePickerShow = true"
        right-icon="arrow-down" />
      <van-popup v-model="patNamePickerShow" round position="bottom">
        <van-picker show-toolbar :columns="patNameList" @cancel="patNamePickerShow = false" @confirm="patNameConfirm" />
      </van-popup>
    </div>
    <div class="list" v-for="(item, index) in hospitalizationRecords" :key="index">
      <div class="item" :class="item.checked ? 'checked' : ''"
        @click="selected(index, item.deptName, item.inDate, item.outDate, item.admissionNum)">
        <div>住院科室：{{ item.deptName }}</div>
        <div>入院日期：{{ item.inDate }}</div>
        <div>出院日期：{{ item.outDate }}</div>
        <div>住院按金余额：{{ item.balance }}元</div>
        <van-tag plain type="primary" size="large" class="status" v-if="item.status === '1'">在&nbsp;院</van-tag>
        <van-tag plain type="success" size="large" class="status" v-if="item.status === '2'">已&nbsp;出&nbsp;院</van-tag>
      </div>
    </div>
    <div class="pay" v-if="status === '1'">
      <input type="number" placeholder="请输入按金金额" @input="inputAmount" :value="cost">
      <div class="btn">
        <van-button type="primary" @click="recharge">支&nbsp;&nbsp;付</van-button>
        <van-button type="info" @click="reset">重&nbsp;&nbsp;置</van-button>
      </div>
    </div>
    <van-empty description="暂无内容" v-if="hospitalizationRecords === ''" />
  </div>
</template>

<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
export default {
  name: "Deposit",
  data () {
    return {
      patName: "",
      patNamePickerShow: false,
      patNameList: [],
      patNameArray: [],
      patNameIndex: 0,
      hospitalizationRecords: [],
      admissionNum: "",
      payData: {},
      deptName: "",
      cost: "",
      status: ""
    };
  },
  created () {
    this.getMembers();
  },
  methods: {
    //获取所有建档人
    async getMembers () {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      let data = qs.stringify({
        openId: window.localStorage.getItem("openId"),
      });
      const { data: res } = await formPost(
        "/wxapp/userBindRecord/userBindRecord/getAllUseInfo",
        data
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.patNameArray = res.data;
        for (let i = 0; i < this.patNameArray.length; i++) {
          this.patNameList.push(res.data[i].name);
        }
        this.patName = this.patNameList[this.patNameIndex];
        this.getHospitalizationRecords();
      } else {
        this.$toast.fail(res.msg);
        setTimeout(() => {
          this.$router.push({
            path: "/file"
          });
        }, 2000);
      }
    },
    //获取住院记录
    async getHospitalizationRecords () {
      this.hospitalizationRecords = [];
      this.cost = "";
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      const { data: res } = await jsonPost(
        "/wxapp/his/getHospitalizationRecords",
        {
          idCard: this.patNameArray[this.patNameIndex].card_no,
          patCardNo: this.patNameArray[this.patNameIndex].pat_card_no,
          patCardType: this.patNameArray[this.patNameIndex].pat_card_type
        }
      );
      this.$toast.clear();
      if (res.code === 0) {
        let list = res.data.item;
        for (let i = 0; i < list.length; i++) {
          if (list[i].status === "1") {
            this.status === "1";
            list[i].outDate = "-";
            list[i].checked = false;
            this.hospitalizationRecords.push(list[i]);
          }
        }
        if (this.hospitalizationRecords.length !== 0) {
          this.status = "1";
        } else {
          this.hospitalizationRecords = "";
          this.status = "2";
          this.$toast("您已出院，不能提供住院按金缴费服务，谢谢合作");
        }
      } else {
        this.hospitalizationRecords = "";
        this.$toast.fail(res.msg);
      }
    },
    //住院按金下单
    async order () {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      const { data: res } = await jsonPost(
        "/wxapp/his/hospitalDepositPayment",
        {
          patCardNo: this.patNameArray[this.patNameIndex].pat_card_no,
          patCardType: this.patNameArray[this.patNameIndex].pat_card_type,
          openId: window.localStorage.getItem("openId"),
          admissionNum: this.admissionNum,
          cost: this.cost
        }
      );
      this.$toast.clear();
      if (res.code === 0) {
        console.log(res);
        this.payData = res.data;
        this.pay();
      } else {
        this.$toast.fail(res.msg);
      }
    },
    // 微信支付
    pay () {
      let that = this;
      function onBridgeReady () {
        WeixinJSBridge.invoke(
          "getBrandWCPayRequest",
          {
            appId: "wxcdae494f7e9422e7",
            timeStamp: that.payData.timeStamp,
            nonceStr: that.payData.nonceStr,
            package: that.payData.package,
            signType: that.payData.signType,
            paySign: that.payData.paySign
          },
          function (res) {
            if (res.err_msg === "get_brand_wcpay_request:ok") {
              that.$router.push({
                path: "/deposit/pay",
                query: {
                  name: that.patNameList[that.patNameIndex],
                  patCardNo: that.patCardNo,
                  deptName: that.deptName,
                  inDate: that.inDate,
                  outDate: that.outDate,
                  patCardType:
                    that.patNameArray[that.patNameIndex].pat_card_type,
                  patCardNo: that.patNameArray[that.patNameIndex].pat_card_no,
                  cost: that.cost,
                  outTradeNo: that.payData.outTradeNo
                }
              });
            } else if (res.err_msg === "get_brand_wcpay_request:cancel") {
              that.$toast.fail("支付未完成");
              that.cost = "";
              that.hospitalizationRecords = {};
              setTimeout(() => {
                that.getHospitalizationRecords();
              }, 2000);
            } else {
              that.$toast(res.err_msg);
            }
          }
        );
      }
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            onBridgeReady,
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
          document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
        }
      } else {
        onBridgeReady();
      }
    },
    patNameConfirm (value) {
      this.patName = value;
      this.patNameIndex = this.patNameList.findIndex(item => {
        return item === value;
      });
      this.patNamePickerShow = false;
      this.getHospitalizationRecords();
    },
    selected (index, deptName, inDate, outDate, admissionNum) {
      this.hospitalizationRecords[index].checked = !this.hospitalizationRecords[
        index
      ].checked;
      this.deptName = deptName;
      this.inDate = inDate;
      this.outDate = outDate;
      this.admissionNum = admissionNum;
    },
    inputAmount (e) {
      this.cost = e.target.value.trim();
    },
    recharge () {
      let flag = false;
      let list = this.hospitalizationRecords;
      for (let i = 0; i < list.length; i++) {
        if (list[i].checked === true) {
          flag = true;
          break;
        }
      }
      if (!flag) {
        this.$toast.fail("请选择按金记录");
      } else if (this.cost === "") {
        this.$toast.fail("输入金额不能为空");
      } else if (this.cost === "0" || this.cost === 0) {
        this.$toast.fail("输入金额不能为零");
      } else if (
        !/^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/.test(
          this.cost
        )
      ) {
        this.$toast.fail("输入金额有误，请重新输入");
      } else {
        this.order();
      }
    },
    reset () {
      this.cost = "";
    }
  }
};
</script>

<style scope>
.deposit_container .header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: white;
}

.deposit_container .header .van-cell {
  border-bottom: 1px solid rgb(201, 191, 191);
  padding: 30px;
}

.deposit_container .header .van-cell::after {
  border: 0;
}

.deposit_container .header .van-field__label {
  text-align: left;
  font-size: 40px;
}

.deposit_container .header .van-field__control {
  text-align: right;
  margin-right: -10px;
  font-size: 40px;
}

.deposit_container .header .van-icon {
  font-size: 40px;
}

.deposit_container .header .van-picker-column {
  font-size: 40px;
}

.deposit_container .header .van-picker__cancel,
.deposit_container .header .van-picker__confirm {
  font-size: 40px;
  padding: 40px;
}

.deposit_container .list .item {
  position: relative;
  border-bottom: 1px solid #f5f5f5;
  padding: 20px 30px;
  font-size: 40px;
}

.deposit_container .list .item div {
  padding: 15px 0;
}

.deposit_container .list .checked {
  background-color: rgb(201, 191, 191);
}

.deposit_container .list .item .status {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  font-size: 40px;
}

.deposit_container .van-tag--large {
  padding: 20px;
}

.deposit_container .pay {
  position: fixed;
  bottom: 60px;
  left: 0;
  background-color: white;
  width: 100%;
  padding: 50px 0;
  text-align: center;
}

.deposit_container .pay input {
  font-size: 50px;
  padding: 20px 0 20px 20px;
}

.deposit_container .pay .btn {
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
  padding: 0 50px;
}

.deposit_container .pay .btn .van-button {
  font-size: 36px;
}
</style>